import React, { useState } from "react";
import patnGamesLogo from "../images/patn-games.svg";
import {Link} from "gatsby";

export default function Header() {
  const [isExpanded] = useState(false);

  return (
    <header className="dark:bg-dark z-10 w-full">
      <div className="flex flex-wrap items-center justify-between max-w-6xl p-4 mx-auto md:p-8">
        <h1 className="flex items-center text-white no-underline m-auto md:m-0">
          <Link to="/">
            <img
              src={patnGamesLogo}
              alt="Patn Games"
            />
          </Link>
        </h1>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
        </nav>
      </div>
    </header>
  )
}
