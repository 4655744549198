import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout";

export default function PrivacyPolicy({data}) {
    const post = data.markdownRemark

    return (
        <Layout>
            <div dangerouslySetInnerHTML={{__html: post.html}} className="markdown">
            </div>
        </Layout>
    )
}

export const query = graphql`
     query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
            }
        }
    }
`