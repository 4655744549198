import React from "react";
import {Link} from "gatsby";

export default function Footer() {
    return (
        <footer className="bg-mainbg">
            <nav className="flex justify-between max-w-6xl p-4 mx-auto text-sm md:p-8">
                <p>
                    <Link to="/privacy"
                        className="font-bold dark:text-white no-underline"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </Link>
                </p>

                <p className="dark:text-white">
                    <b>Patn Games 2021</b>
                </p>
            </nav>
        </footer>
    )
}
